import { Amplify } from 'aws-amplify';

import { Authenticator, withAuthenticator } from '@aws-amplify/ui-react';
import type { WithAuthenticatorProps } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import './output.css'
import config from './aws-exports';
import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { I18n } from 'aws-amplify/utils';
import { translations } from '@aws-amplify/ui-react';
I18n.putVocabularies(translations);
I18n.setLanguage('es');

I18n.putVocabulariesForLanguage('es', {
  Username: "Correo Electrónico",
  "Please confirm your Password": "Confirme su contraseña",
  "Enter your Username": "Escriba su correo"
});

Amplify.configure(config);

async function currentSession() {
  try {
    const session = await fetchAuthSession();
    const accessToken = session.tokens?.accessToken?.toString() ?? '';
    const idToken = session.tokens?.idToken?.toString() ?? '';
    return { accessToken, idToken };
  } catch (err) {
    console.error("Error fetching session: ", err);
    return { accessToken: '', idToken: '' };  // Garantiza devolver siempre strings
  }
}

interface TokenState {
  accessToken: string;
  idToken: string;
}

export function App({ signOut, user }: WithAuthenticatorProps) {
  const [tokens, setTokens] = useState<TokenState>({ accessToken: '', idToken: '' });

  useEffect(() => {
    async function loadSession() {
      const { accessToken, idToken } = await currentSession();
      setTokens({ accessToken, idToken });
    }
    loadSession();
  }, []);
  

  const handleFetchData = async () => {
    try{ 
      const response = await axios.get('/bienbe', {
        headers: {
          'Authorization': 'Bearer ' + tokens.accessToken.toString()
        }
      });
      console.log(response.data)
      toast.success("Backend OK for userId: " + response.data.userId);
    } catch (error) {
      toast.error('Hubo un error del lado del backend.');
      console.error('Error fetching data:', error);
    }
  };

  return (
    <Authenticator>
      <div className="flex mx-auto p-6 bg-slate-300 justify-center min-h-screen">
        <div className=''>
          <div className="px-4">
            <h3 className="text-base font-semibold leading-7 text-gray-900">{user?.signInDetails?.loginId || 'User'}</h3>
            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">{user?.username || 'User'}</p>
          </div>
          <div className="mt-6 border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Access Token</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex flex-wrap w-[32rem] break-all">{tokens?.accessToken.toString() || 'Token'}</dd>
              </div>
            </dl>
          </div>        
          <div className='mt-6 flex items-center justify-center gap-x-6'>
            <button className='rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600' onClick={handleFetchData}>Fetch Data</button>
            <button className='rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600' onClick={signOut}>Sign out</button>
          </div>
        </div>
        <Toaster />
      </div>
    </Authenticator>
  );
}

export default withAuthenticator(App);